const dashPtMessages = {
  'dashboard.title': 'Didati',
  'dashboard.description': 'Seu portal para Gestão Educacional',
  'dashboard.activeStudents':
    '{count, plural, =0 {Nenhum Aluno Ativo} one {Aluno Ativo} other {Alunos Ativos}}',

  'dashboard.redirect-title': 'Olá Professor (a)!',
  'dashboard.redirect-text-link':
    'Estamos atualizando o nosso sistema para oferecer uma melhor experiência, aguarde {time} segundos ou clique nesse {link} para ser redirecionado.',
  'dashboard.redirect-observation':
    'Não se preocupe, todas as funcionalidades permanecem as mesmas.',
  'dashboard.acknowledgment': 'Agradecemos por seu apoio. Atenciosamente,',

  'dashboard.no-enrollments-message':
    'Você não está inscrito em nenhuma turma no momento. Clique aqui para realizar a sua inscrição.',

  'dashboard.class-enrolls': 'Turmas inscritas',

  'dashboard.planned-lesson': 'Aula planejada',

  'dashboard.register-modal-text':
    'Cadastro realizado com sucesso. Sua matrícula é {studentRegistrationNumber}.',
  'dashboard.released-lesson': 'Aula lançada',

  'dashboard.welcome': 'Bem-vindo(a)',

  'dashboard.tour-welcome': 'Seja bem-vindo(a) ao Didati!',

  'dashboard.all-students-grade': 'Total de alunos por turma',
  'dashboard.no-grades': 'Nenhuma turma cadastrada!',
  'dashboard.total-students': 'Total de alunos',
};

export default dashPtMessages;
