import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import api from 'services/api';

export const getTotalStudents = createAsyncThunk(
  'adminDash/getTotalStudents',
  async (_, { getState }) => {
    const {
      menuYear: { selectedYear },
    } = getState();

    const response = await api.get(
      `dashboard/academic-year/${selectedYear.id}`
    );
    const data = await response.data;

    return data;
  }
);

const adminDashAdapter = createEntityAdapter({});

const adminDashSlice = createSlice({
  name: 'adminDash',
  initialState: adminDashAdapter.getInitialState({
    studentOverview: {},
  }),

  reducers: {},

  extraReducers: {
    [getTotalStudents.fulfilled]: (state, { payload }) => {
      state.studentOverview = payload;
    },
  },
});

export default adminDashSlice.reducer;
